@import 'assets/styles/mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* MODAL */

.modal {
  .modal-content {
    border: none;
  }
  .modal-header {
    .close {
      background: $white;
    }
  }
}

.modal-backdrop {
  background: $black;
  &.show {
    opacity: 0.3;
  }
}
