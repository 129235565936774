@import 'assets/styles/mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* UTILITIES */

// font color
.text {
  &-default {
    color: $default !important;
  }
  &-primary {
    color: $primary !important;
  }
  &-secondary {
    color: $secondary !important;
  }
  &-success {
    color: $success !important;
  }
  &-danger {
    color: $danger !important;
  }
  &-warning {
    color: $warning !important;
  }
  &-info {
    color: $info !important;
  }
  &-light {
    color: $light !important;
  }
  &-dark {
    color: $dark !important;
  }
  &-white {
    color: $white !important;
  }
  &-muted {
    color: $gray-5 !important;
  }
}

// background color
.bg {
  &-default {
    background-color: $default !important;
  }
  &-primary {
    background-color: $primary !important;
  }
  &-secondary {
    background-color: $primary !important;
  }
  &-success {
    background-color: $success !important;
  }
  &-danger {
    background-color: $danger !important;
  }
  &-warning {
    background-color: $warning !important;
  }
  &-info {
    background-color: $info !important;
  }
  &-light {
    background-color: $light !important;
  }
  &-dark {
    background-color: $dark !important;
  }
  &-white {
    background-color: $white !important;
  }
}
